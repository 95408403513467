import Peepo from "./Peepo";
import Terms from "./Terms";
import Home from "./Home";
import Privacy from "./Privacy";
import ConfigForm from "./ConfigForm";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function BasicExample() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/app/">
            <Home />
          </Route>
          <Route path="/app/peepo/">
            <Peepo />
          </Route>
          <Route path="/app/config/">
            <ConfigForm />
          </Route>
          <Route path="/app/terms/">
            <Terms />
          </Route>
          <Route path="/app/privacy/">
            <Privacy />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

declare global {
  var e: any;
}
