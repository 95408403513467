import { Icon, Container, Menu, Popup, Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { useEffect, useState } from "react";
import axios from "axios";
import "./Home.css";

function Home() {
  const [loginLink, setLoginLink] = useState("");
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched) {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/api/oauth/`)
        .then(({ data: link, headers: headers }) => {
          setLoginLink(link)
          setFetched(true)
        }).catch((err) => {
          window.location.href = `${process.env.REACT_APP_API_HOST}/app/config/`
          console.log('error', err);
          setFetched(true)
        })
    }
  }, [fetched]);

  //todo: api fetch
  return (
    <Container style={{ height: '100vh' }}>
      <Grid textAlign='center' verticalAlign='middle' style={{ height: '100%' }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='teal' textAlign='center'>
            <Image src='https://malgasm.com/images/okayge.png' /> animated peepo
          </Header>
          <Form size='large'>
            <Segment>
              <Button id="peepoOauthLink" href={loginLink} color='purple' fluid size='large'>
                <Icon name="discord" />
                login
              </Button>
            </Segment>
          </Form>
          <Message>
            <Popup
              trigger={
                <a color='teal' href="#">what is this?</a>
              }
              content="This is a no-download PNGtuber app that uses discord. Visit malgasm.com/peepo for more info!"
            />
          </Message>
        </Grid.Column>
      </Grid>
      <Menu fixed="bottom" secondary className="homeFooterMenu">
        <a className="twitchLink" href="https://www.twitch.tv/malgasm" target="_blank" rel="noopener noreferrer">
          <Button color="purple">
            <Icon name="twitch" /> made by malgasm
          </Button>
        </a>
        <Menu.Menu position="right">
          <Menu.Item><a href="/app/terms" className="homeFooterMenuLink" target="_blank" rel="noopener noreferrer">terms</a></Menu.Item>
          <Menu.Item><a href="/app/privacy" className="homeFooterMenuLink" target="_blank" rel="noopener noreferrer">privacy</a></Menu.Item>
        </Menu.Menu>
      </Menu>
    </Container>
  );
}

export default Home;
