import { useEffect, useState } from "react";
import axios from "axios";
import cookie from "react-cookies";
import {
  Button,
  Popup,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Divider,
  Segment,
  Input,
  Form,
  Menu
} from "semantic-ui-react";

import "./ConfigForm.css";

type availableColor = "orange" | "black"

export default function ConfigForm() {
  const [staticUrl, setStaticUrl] = useState("");
  const [animatedUrl, setAnimatedUrl] = useState("");
  const [peepoUrl, setPeepoUrl] = useState("");
  const [followingButtonText, setFollowingButtonText] = useState("");
  const [followingButtonColor, setFollowingButtonColor] = useState("purple" as availableColor);
  const [fetched, setFetched] = useState(false);
  const [csrfToken, setCsrfToken] = useState("");

  useEffect(() => {
    if (!fetched) {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/api/config/`)
        .then(({ data: config, headers: headers }) => {

          // const csrfToken = cookie.load("XSRF-TOKEN", { path: "/" }); //todo: what does this second arg do?
          const csrfToken = cookie.load("XSRF-TOKEN");
          setCsrfToken(csrfToken);

          setAnimatedUrl(config.animated_image_url);
          setStaticUrl(config.static_image_url);

          setPeepoUrl(
            `${process.env.REACT_APP_HOST_URL}/app/peepo/${config.discord_user_id}`
          );

          setFollowingButtonText(config.is_following ? "stop" : "start")
          if (config.is_following)
            setFollowingButtonColor("orange" as availableColor)

          setFetched(true);
        })
        .catch((e) => {
          console.log('an error occurred', e);
          // window.location.href = process.env.REACT_APP_API_HOST || "";
        });
    }
    if (animatedUrl) {
      updateAnimatedImage(animatedUrl);
    }
    if (staticUrl) {
      updateStaticImage(staticUrl);
    }
  }, [fetched, staticUrl, animatedUrl]);

  const updateStaticImage = (imageUrl: string) => {
    setStaticUrl(imageUrl);
    document
      ?.getElementById("static_image_preview")
      ?.setAttribute("src", imageUrl);
  };

  const updateAnimatedImage = (imageUrl: string) => {
    setAnimatedUrl(imageUrl);
    document
      ?.getElementById("animated_image_preview")
      ?.setAttribute("src", imageUrl);
  };

  const configFormAction = `${process.env.REACT_APP_API_HOST}/config`;
  const joinFormAction = `${process.env.REACT_APP_API_HOST}/join`;
  const logoutFormUrl = `${process.env.REACT_APP_API_HOST}/logout`;

  const submitImagesForm = () => {
    const form = document.getElementById("imagesform") as HTMLFormElement
    form.submit()
  };

  return (
    <Container className={fetched ? 'loaded':''}>
      <Divider hidden />

      <Segment.Group>
        <Segment inverted color="black">
          <Menu inverted borderless>
            <Menu.Item>
              <Header as="h1" color="purple">
                Your Peepo:
              </Header>
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item>
                <a href={logoutFormUrl}>
                  <Popup
                    trigger={
                      <Button color="black">
                        <Icon name="sign-out" />
                      </Button>
                    }
                    content="animated peepo still works after logout. logging in is only for updating your images or retrieving your peepo url"
                    position="top right"
                  />
                </a>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Segment>

        <Segment inverted color="black">
          <Form id="imagesform" method="post" action={configFormAction}>
            <Grid container stretched fluid>
              <Grid.Column width={8}>
                <Segment className="staticImageConfigContainer" inverted color="purple" >
                  <Header textAlign="center" size="medium">
                    Static Image
                  </Header>
                  <Image id="static_image_preview" centered />
                  <Divider hidden />
                  <Input id="static_image_url" fluid type="text" autoFocus="autofocus" className="inputAnimatedImageUrl peepoConfigInput" tabIndex="1" name="static_image_url" value={staticUrl} onChange={(evt) => { updateStaticImage(evt.target.value); }} />
                  <input type="hidden" value={csrfToken} name="_csrf" />
                </Segment>
              </Grid.Column>

              <Grid.Column width={8}>
                <Segment className="animatedImageConfigContainer" inverted color="purple" >
                  <Header textAlign="center" size="medium">
                    Animated Image
                  </Header>
                  <Image id="animated_image_preview" centered />
                  <Divider hidden />
                  <Input id="animated_image_url" fluid type="text" tabIndex="2" className="inputAnimatedImageUrl peepoConfigInput" name="animated_image_url" value={animatedUrl} onChange={(evt) => { updateAnimatedImage(evt.target.value); }} />
                </Segment>
              </Grid.Column>
            </Grid>
          </Form>
        </Segment>


        <Segment inverted color="black">
          <Button color="purple" id="save" onClick={submitImagesForm} type="submit" tabIndex="3" >
            save images <Icon name="save" />
          </Button>
        </Segment>

        <Segment inverted color="black">
          <Form id="jointoggleform" method="post" action={joinFormAction}>
            <Button type="submit" color={followingButtonColor} tabIndex="3">
              {followingButtonText} following
            </Button>
            <input type="hidden" value={csrfToken} name="_csrf" />
          </Form>
        </Segment>

        <Segment inverted color="black">
          <h3>your peepo url: </h3>
          <Input
            id="peepo_url"
            fluid
            className="peepoUrl peepoConfigInput"
            readOnly={true}
            type=""
            value={peepoUrl}
          />
          <em className="details">(use this as your browser source)</em>
          <Divider hidden />
          <a href={peepoUrl} rel="noreferrer" target="_blank">
            <Button id="visit_peepo" color="purple">visit</Button>
          </a>
        </Segment>

      </Segment.Group>
    </Container>
  );
}
