import { useCallback, useEffect, useState, useMemo } from "react";
import { io } from "socket.io-client";
import axios from "axios";
import "./Peepo.css";

function Peepo() {
  const [staticImage, setStaticImage] = useState("");
  const [animatedImage, setAnimatedImage] = useState("");
  const [fetched, setFetched] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isConnected, setConnected] = useState(false);
  const [peepoClass, setPeepoClass] = useState("Peepo");

  const discordUserId = window.location.pathname
    .replace("/app/peepo/", "")
    .replace("/", "");

  const sock = useMemo(() => {
    const sock = io(process.env.REACT_APP_API_HOST);

    sock.on("connect", () => {
      if (!isConnected) {
        console.log(`connected (${discordUserId})`);
        setConnected(true);
      }
    });

    sock.on("disconnect", () => {
      console.log("disconnected");
      setConnected(false);
    });

    return sock;
  }, [setConnected]);

  const handleDiscordUpdate = useCallback(
    (socket) => {
      console.log("images", staticImage, animatedImage);
      if (socket.isSpeaking) {
        console.log("setting current image to", animatedImage);
        setCurrentImage(animatedImage);
        setPeepoClass("Peepo isSpeaking");
      } else {
        console.log("setting current image to", staticImage);
        setPeepoClass("Peepo");
        setCurrentImage(staticImage);
      }
      console.log(`test event received for ${discordUserId}`, socket);
    },
    [animatedImage, staticImage, discordUserId]
  );
  useEffect(() => {
    if (!fetched) {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/api/users/${discordUserId}`)
        .then(function (resp) {
          if (resp && resp.data !== "") {
            setCurrentImage(resp.data.static_image_url);
            setStaticImage(resp.data.static_image_url);
            setAnimatedImage(resp.data.animated_image_url);
          } else {
            console.log("no data!");
            window.location = process.env.REACT_APP_HOST_URL;
            sock.disconnect();
          }
        })
        .catch((evt) => {
          console.log("error!", evt);
        });
    }

    setFetched(true);

    if (isConnected) {
      try {
        sock.off(discordUserId, handleDiscordUpdate);
      } catch (e) {
        console.log("offError", e);
      }
      sock.on(discordUserId, handleDiscordUpdate);
      // sock.on("data", (uwu) => {
      //   console.log('data', uwu);
      // });

    } else {
    }

    window.socket = sock;
  }, [
    fetched,
    isConnected,
    staticImage,
    animatedImage,
    discordUserId,
    handleDiscordUpdate,
    setFetched,
    sock,
  ]);

  return (
    <div className={peepoClass}>
      <header className="Peepo-header">
        <img src={currentImage} alt="" />
      </header>
    </div>
  );
}

export default Peepo;
